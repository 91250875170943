<template>
  <PublicApply :post="post"></PublicApply>
</template>
<script>
import PublicApply from './PublicApply'
export default {
  name: 'Lawyer',
  data () {
    return {
      post: {
        service_type: '预约-律师服务',
        type: '律师事务所',
        service_name: '律师服务预约办理',
        info: '' +
          '<p>1.申请方保证以上本人填写的情况及提供的资料真实有效；<p>' +
          '<p>2.申请方应当真实、详尽和及时地向律师叙述和代理事项有关的情况，提供与委托代理事项有有关证明和证据材料；</p>' +
          '<p>3.申请方应当积极、主动地配合律师的工作，申请人对律师提出的要求应当明确、合理;</p>' +
          '<p>4.申请方应当按时、足额支付律师代理费和工作费用;</p>' +
          '<p>5.申请方有责任对委托代理事项作出独立的判断、决策，申请方根据律师提供的法律意见、建议、方案所作出的决定而导致的损失，非因律师错误运用法律等失职行为造成的，由申请方自行承担。\n</p>'
      }
    }
  },
  components: {
    PublicApply
  }
}
</script>
<style scoped >
</style>
